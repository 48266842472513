.pagination-previous,
.pagination-next,
.pagination-link {
  /* Not set by default, but buttons have grey, not white, backgrounds */
  background-color: white;
}

.loader {
  height: 6em;
  margin: 3em auto;
  width: 6em;
}
