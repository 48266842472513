.page-header {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  h2 {
    flex-grow: 1;
    font-size: 1.5rem;
    font-weight: bold;
  }

  h3 {
    flex-grow: 1;
    font-size: 1.25rem;
    font-weight: bold;
  }
}


.field {
  .label {
    .button {
      float: right;
    }
  }
}

.button + .button {
  margin-left: 1em;
}

.is-expiring {
  @at-root tr#{&} {
    color: $warning-text;
  }
  @at-root .panel-block div#{&} {
    background-color: $warning;
    color: $warning-dark;

    .button {
      @extend .is-warning;
      @extend .is-light;
    }
  }
}

.is-expired {
  @at-root tr#{&} {
    color: $danger-dark;
  }
  @at-root .panel-block div#{&} {
    background-color: $danger;
    color: $white;

    .button {
      @extend .is-danger;
      @extend .is-light;
    }
  }
}
